import { PackagesSearchFormProps } from "../../../types/packages/PackagesSearchFormProps";
import styled from "styled-components";
import DatesRangeField from "./PackageDateField";
import { Breakpoint } from "../../../enums/Breakpoint";
import { useTranslation } from "../../../i18n";
import Button from "../../shared/Button";
import ErrorMessage from "../../shared/ErrorMessage";
import PackageLocationAutocompleteField from "./PackageLocationAutocompleteField";
import DepartureAirportAutocompleteField from "./DepartureAirportAutocompleteField";
import { InputKey, useWizardContext } from "../../../contexts/packages/WizardContext";
import ExternalLayoutPerks from "../../shared/ExternalLayout/ExternalLayoutPerks";
import { LayoutType } from "../../../enums/LayoutType";
import RoomsCountField from "../../SharedSearchFormFields/Hotels/ExternalLayout/RoomsCountField/RoomsCountField";

const PackagesSearchForm = ({
  errors,
  hasErrors,
  onChange,
  onSubmit,
  touched,
  values,
}: PackagesSearchFormProps) => {
  const { t } = useTranslation();
  const { isWizard, mobileModalAnimations, setInputRef, stopWizard } = useWizardContext();

  return (
    <ExternalLayoutFormContainer>
      <SearchForm onSubmit={onSubmit} noValidate>
        <FieldsContainer>
          <DepartureAirportAutocompleteField
            errors={errors}
            onChange={onChange}
            touched={touched}
            values={values}
          />
          <PackageLocationAutocompleteField
            errors={errors}
            onChange={onChange}
            touched={touched}
            values={values}
          />
          <DatesRangeField errors={errors} onChange={onChange} touched={touched} values={values} />
          <RoomsCountField
            onChange={onChange}
            values={values.rooms}
            onSubmit={onSubmit}
            isWizard={isWizard}
            mobileModalAnimations={mobileModalAnimations}
            setInputRef={setInputRef(InputKey.RoomCount)}
            stopWizard={stopWizard}
          />
          <SubmitButton type="submit" onClick={onSubmit} variant="rectangle">
            {t("tix_search_form_search_packages")}
          </SubmitButton>
        </FieldsContainer>
        {hasErrors && (
          <ErrorsRow>
            <FormErrorMessage>
              <ErrorMessage
                error="tix_search_form_err_packages_search_form_global"
                layout={LayoutType.External}
              />
            </FormErrorMessage>
          </ErrorsRow>
        )}
      </SearchForm>
      <ExternalLayoutPerks />
    </ExternalLayoutFormContainer>
  );
};

const ExternalLayoutFormContainer = styled.div`
  padding: 20px 0 16px 0;
  background: ${({ theme }) => theme.darkBackground};

  @media ${Breakpoint.MinTablet} {
    border-radius: ${({ theme }) => theme.ovalBorderRadius};
    padding: 10px 0 12px 0;
  }

  @media ${Breakpoint.MaxMobile} {
    ${({ theme }) =>
      !!theme.form.container?.mobileBackgroundColor &&
      `background: ${theme.form.container?.mobileBackgroundColor};`}
  }
`;

const SearchForm = styled.form`
  padding: 0px 20px;
`;

const FieldsContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  @media ${({ theme }) => theme.breakpoints.maxMobile} {
    gap: 5px;
  }

  > * {
    border-radius: ${({ theme }) => theme.ovalBorderRadius};
    width: calc((100% - 40px) / 5);

    @media ${({ theme }) => theme.breakpoints.maxTablet} {
      width: calc((100% - 10px) / 2);
    }

    @media ${({ theme }) => theme.breakpoints.maxMobile} {
      flex-basis: 100%;
    }
  }
`;

const ErrorsRow = styled.div`
  margin: 10px;
  align-items: flex-start;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
`;

const FormErrorMessage = styled.div`
  display: inline-flex;
`;

const SubmitButton = styled(Button)`
  font-size: 13px;
  font-family: ${({ theme }) => theme.heavyFont};
  display: flex;
  margin: 0 0 0 auto;
  height: 80px;
  border-radius: ${({ theme }) => theme.ovalBorderRadius};

  @media ${Breakpoint.MinTablet} {
    font-size: 20px;
  }

  @media ${Breakpoint.MaxTablet} {
    width: 100%;
  }

  @media ${Breakpoint.MaxMobile} {
    height: 70px;
  }
`;

export default PackagesSearchForm;
